import { SURVEY_SERVICE } from 'chimera/all/plugins/eventbus/events'
import { trackExperimentSetEvent, triggerSurvey } from './events'

/**
 * Our event handling for Hotjar Event.
 *
 * @param {object} Vue
 * @param {object} options
 * @param {Function} options.enqueueEvent
 */
export function bindEvents(Vue, { enqueueEvent }) {
  // Track event.
  Vue.$eventBus.onExperimentSetEvent((event) => {
    enqueueEvent('onExperimentSetEvent', () => trackExperimentSetEvent(event))
  })

  Vue.$eventBus.onSurveyEvent(({ name, service }) => {
    if (service !== SURVEY_SERVICE.HOTJAR) {
      return
    }

    triggerSurvey(name)
  })
}
