import Hotjar from '@hotjar/browser'

/**
 * @param {object} event
 * @param {object} event.experiment
 */
export function trackExperimentSetEvent({ experiment }) {
  const { variant, abSmartlyId, id } = experiment

  /**
   * If the experiment id is not set, we don't want to track it
   * This occurs when getting the variantValues from ABSmartly
   * PD-5294
   */
  if (id === undefined) {
    return
  }

  const ticketId = id.substring(0, id.indexOf(' | '))
  const variantAsLetter = String.fromCharCode(
    97 + parseInt(variant),
  ).toUpperCase()

  const eventName = `${ticketId}_${variantAsLetter}_${abSmartlyId}_assigned`

  Hotjar.event(eventName)
}

/**
 * @param {string} name
 */
export function triggerSurvey(name) {
  if (!Hotjar.isReady()) {
    return
  }

  Hotjar.event(name)
}
