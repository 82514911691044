<template>
  <div class="page-thank-you">
    <CompanyMatches :companies="companies" />

    <div
      v-if="companies.length > 0"
      class="page-thank-you__container container"
    >
      <div id="usps" class="usps usps__container">
        <h2 class="usps__title">{{ $t('tips_title') }}</h2>
        <Usps :usps="usps" />
      </div>
    </div>

    <div class="page-thank-you__cross-sell">
      <div class="cross-sell">
        <div class="cross-sell__wrapper container">
          <div class="cross-sell__text">
            <h2 class="cross-sell__title">
              {{ $t('cross-sell-title') }}
            </h2>

            <p class="cross-sell__body">
              {{ $t('cross-sell-body') }}
            </p>
          </div>

          <div class="cross-sell__image">
            <img :src="huisprojecten" alt="Huisprojecten.nl" />
          </div>
        </div>
      </div>

      <div class="cross-sell__bottom">
        <div class="flex justify-start md:w-1/2">
          <TBtnLink
            id="linkToHuisprojecten"
            class="bg-primary hover:bg-primary-dark"
            href="https://www.huisprojecten.nl"
            target="_blank"
          >
            {{ $t('cross-sell-button') }}
          </TBtnLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import AbstractPage from 'chimera/all/components/page/AbstractPage'
import { ExperimentSource } from 'chimera/all/mixins/usesExperiments'
import CompanyMatches from 'chimera/all/page/thankYou/CompanyMatches.vue'

export default {
  components: {
    CompanyMatches,
    Usps,
  },

  extends: AbstractPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      usps: [
        {
          text: this.$t('usps_1'),
          logo: require('chimera/all/themes/blueflow/images/usps/calendar.svg'),
        },
        {
          text: this.$t('usps_2'),
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg'),
        },
        {
          text: this.$t('usps_3'),
          logo: require('chimera/all/themes/blueflow/images/usps/safety.svg'),
        },
      ],
      huisprojecten: require('chimera/all/assets/images/huisprojecten_screen.png'),
      companies: [],
      experiment: undefined,
    }
  },

  /**
   *
   */
  async beforeMount() {
    this.companies = this.$store.getters['lead/getResult']('companies', [])

    // https://bambelo.absmartly.com/experiments/373
    this.experiment = await this.$store.dispatch('experiments/get', {
      id: 'PD-7767',
      source: ExperimentSource.ABSMARTLY,
    })
  },
}
</script>

<i18n>
{
  "nl": {
    "title": "Hartelijk dank voor je aanvraag!",
    "subtitle": "De specialisten nemen zo spoedig mogelijk contact met je op.",
    "tips_title": "Tips om even door te nemen",
    "usps_1": "Zorg dat je je telefoon en agenda bij de hand hebt, want specialisten kunnen je telefonisch benaderen.",
    "usps_2": "Kijk niet alleen naar de prijs, maar vergelijk ook op kwaliteit, service en betrouwbaarheid.",
    "usps_3": "Neem rustig de tijd om voor de juiste specialist te kiezen.",
    "cross-sell-title": "Bespaar ook op de kosten van andere diensten",
    "cross-sell-body": "Ga nu naar Huisprojecten.nl en vind gratis en vrijblijvend de beste deals van meer dan 2.000 geselecteerde specialisten.",
    "cross-sell-button": "Ga naar Huisprojecten.nl"
  },
  "nl-BE": {
    "title": "Hartelijk dank voor uw aanvraag!",
    "subtitle": "De specialisten nemen zo spoedig mogelijk contact met u op.",
    "tips_title": "Tips om even door te nemen",
    "usps_1": "Zorg dat u uw telefoon en agenda bij de hand hebt, want specialisten kunnen u telefonisch benaderen.",
    "usps_2": "Kijk niet alleen naar de prijs, maar vergelijk ook op kwaliteit, service en betrouwbaarheid.",
    "usps_3": "Neem rustig de tijd om voor de juiste specialist te kiezen.",
    "cross-sell-title": "Bespaar ook op andere diensten",
    "cross-sell-body": "Bezoek nu Huisprojecten.be en vind gratis en vrijblijvend de beste deals van meer dan 2.000 geselecteerde specialisten.",
    "cross-sell-button": "Bezoek Huisprojecten.be"
  },
  "es-ES": {
    "title": "¡Gracias por tu solicitud!",
    "subtitle": "Los especialistas se pondrán en contacto contigo lo antes posible.",
    "tips_title": "Consejos para revisar",
    "usps_1": "Asegúrate de tener tu teléfono y agenda a mano, ya que los especialistas pueden contactarte por teléfono.",
    "usps_2": "No mires solo el precio, sino también la calidad, el servicio y la fiabilidad.",
    "usps_3": "Tómate tu tiempo para elegir al especialista adecuado.",
    "cross-sell-title": "Ahorra también en otros servicios",
    "cross-sell-body": "Visita ahora Huisprojecten.es y encuentra gratuitamente las mejores ofertas de más de 2.000 especialistas seleccionados.",
    "cross-sell-button": "Ir a Huisprojecten.es"
  },
  "de-DE": {
    "title": "Vielen Dank für Ihre Anfrage!",
    "subtitle": "Die Spezialisten werden sich so schnell wie möglich mit Ihnen in Verbindung setzen.",
    "tips_title": "Tipps zum Durchsehen",
    "usps_1": "Stellen Sie sicher, dass Sie Ihr Telefon und Ihren Terminkalender zur Hand haben, da die Spezialisten Sie telefonisch kontaktieren können.",
    "usps_2": "Achten Sie nicht nur auf den Preis, sondern vergleichen Sie auch Qualität, Service und Zuverlässigkeit.",
    "usps_3": "Nehmen Sie sich die Zeit, den richtigen Spezialisten auszuwählen.",
    "cross-sell-title": "Sparen Sie auch bei anderen Dienstleistungen",
    "cross-sell-body": "Besuchen Sie jetzt Huisprojecten.de und finden Sie kostenlos die besten Angebote von mehr als 2.000 ausgewählten Spezialisten.",
    "cross-sell-button": "Besuchen Sie Huisprojecten.de"
  },
  "fr-BE": {
    "title": "Merci pour votre demande!",
    "subtitle": "Ces spécialistes vous contacteront dès que possible.",
    "tips_title": "Conseils à consulter",
    "usps_1": "Assurez-vous d'avoir votre téléphone et votre agenda à portée de main, car les spécialistes peuvent vous contacter par téléphone.",
    "usps_2": "Ne regardez pas seulement le prix, mais comparez aussi la qualité, le service et la fiabilité.",
    "usps_3": "Prenez le temps de choisir le bon spécialiste.",
    "cross-sell-title": "Économisez également sur d'autres services",
    "cross-sell-body": "Rendez-vous maintenant sur Huisprojecten.be et trouvez gratuitement les meilleures offres de plus de 2 000 spécialistes sélectionnés.",
    "cross-sell-button": "Rendez-vous sur Huisprojecten.be"
  },
  "fr-FR": {
    "title": "Merci pour votre demande!",
    "subtitle": "Les spécialistes vous contacteront dès que possible.",
    "tips_title": "Conseils à consulter",
    "usps_1": "Assurez-vous d'avoir votre téléphone et votre agenda à portée de main, car les spécialistes peuvent vous contacter par téléphone.",
    "usps_2": "Ne regardez pas seulement le prix, mais comparez aussi la qualité, le service et la fiabilité.",
    "usps_3": "Prenez le temps de choisir le bon spécialiste.",
    "cross-sell-title": "Économisez également sur d'autres services",
    "cross-sell-body": "Rendez-vous maintenant sur Huisprojecten.fr et trouvez gratuitement les meilleures offres de plus de 2 000 spécialistes sélectionnés.",
    "cross-sell-button": "Rendez-vous sur Huisprojecten.fr"
  },
  "it-IT": {
    "title": "Grazie per la tua richiesta!",
    "subtitle": "Questi specialisti ti contatteranno il prima possibile.",
    "tips_title": "Suggerimenti da rivedere",
    "usps_1": "Assicurati di avere il telefono e l'agenda a portata di mano, poiché gli specialisti potrebbero contattarti telefonicamente.",
    "usps_2": "Non guardare solo il prezzo, ma confronta anche qualità, servizio e affidabilità.",
    "usps_3": "Prenditi il tempo necessario per scegliere il giusto specialista.",
    "cross-sell-title": "Risparmia anche su altri servizi",
    "cross-sell-body": "Visita subito Huisprojecten.it e trova gratuitamente le migliori offerte di oltre 2.000 specialisti selezionati.",
    "cross-sell-button": "Visita Huisprojecten.it"
  }
}
</i18n>
